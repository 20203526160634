import React from "react";
import { NavigationLink } from "../NavigationLink";
import { Stack } from "react-bootstrap";
import { useScroll } from "../../hooks";
import { classes } from "../../utils";
import styles from "./Footer.module.scss";
import logo from "./logo.svg";

interface IProps {}

export const Footer: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const scroll = useScroll();

  const year: number = (new Date()).getFullYear();

  return (
    <React.Fragment>
      <footer
        className={classes("d-flex d-md-none flex-column justify-content-start align-items-center", styles.footer)}
      >
        <img className={classes("mt-4", styles.mobileLogo)} src={logo} alt="logo" />
        <Stack direction="vertical" className="align-items-center my-40">
          <div
            className={classes("text-decoration-none text-white ff-triakis fw-normal fs-3", styles.navigationLink)}
            role="button"
            onClick={scroll.toServicesPanel}
          >Services</div>
          <div
            className={classes("text-decoration-none text-white ff-triakis fw-normal fs-3 mt-2", styles.navigationLink)}
            role="button"
            onClick={scroll.toTechnologiesPanel}
          >Technology</div>
          <div
            className={classes("text-decoration-none text-white ff-triakis fw-normal fs-3 mt-2", styles.navigationLink)}
            role="button"
            onClick={scroll.toCaseStudyPanel}
          >Case study</div>
          <div
            className={classes("text-decoration-none text-white ff-triakis fw-normal fs-3 mt-2", styles.navigationLink)}
            role="button"
            onClick={scroll.toAboutUsPanel}
          >About us</div>
          <div
            className={classes("text-decoration-none text-white ff-triakis fw-normal fs-3 mt-2", styles.navigationLink)}
            role="button"
            onClick={scroll.toGetInTouchPanel}
          >Get in touch</div>
        </Stack>
        <Stack direction="horizontal" className={classes("w-100", styles.mobileContacts)}>
          <div className={classes("flex-grow-1 d-flex flex-column text-white ff-triakis fw-normal p-4")}>
            <span className="mb-1 text-white">
              Great Britain, London
              <br />
              Grand st. 23/2
              <br />
              Office 777
              <br />
              45577899
            </span>
            <a href="mailto:manager@shapeshift.ai" className="text-white text-decoration-none mt-2 mb-1">
              manager@shapeshift.ai
            </a>
            <a href="mailto:tech@shapeshift.ai" className="text-white text-decoration-none mt-2 mb-1">
              tech@shapeshift.ai
            </a>
            <a href="tel:+447975777666" className="text-white text-decoration-none mt-2">
              +447975777666
            </a>
          </div>
          <Stack direction="vertical" className={styles.links}>
            <NavigationLink
              to=""
              className={classes("h-100 flex-grow-1 fs-6", styles.link)}
              label="/ln"
            />
            <NavigationLink
              to=""
              className={classes("h-100 flex-grow-1 fs-6", styles.link, styles.central)}
              label="/fb"
            />
            <NavigationLink
              to=""
              className={classes("h-100 flex-grow-1 fs-6", styles.link)}
              label="/tw"
            />
          </Stack>
        </Stack>
        <div className={classes("w-100 d-flex justify-content-start align-items-center", styles.mobileRights)}>
          <span className="text-white ff-triakis fw-normal">
            All rights reserved {year}
            <br />
            Designed by ShapeShift.AI
          </span>
        </div>
      </footer>
      <footer
        className={classes(
          "d-none d-md-flex flex-column justify-content-start align-items-stretch",
          styles.footer,
        )}
      >
        <div className={classes("d-flex", styles.first)}>
          <div className="flex-grow-1 d-flex justify-content-start align-items-start">
            <img className={classes("mt-4", styles.logo)} src={logo} alt="logo" />
            <Stack direction="vertical" className={styles.navigation}>
              <div className={classes("text-decoration-none ff-triakis fw-normal", styles.navigationLink)}>
                <span role="button" onClick={scroll.toServicesPanel}>Services</span>
              </div>
              <div className={classes("text-decoration-none ff-triakis fw-normal", styles.navigationLink)}>
                <span role="button" onClick={scroll.toTechnologiesPanel}>Technology</span>
              </div>
              <div className={classes("text-decoration-none ff-triakis fw-normal", styles.navigationLink)}>
                <span role="button" onClick={scroll.toCaseStudyPanel}>Case study</span>
              </div>
              <div className={classes("text-decoration-none ff-triakis fw-normal", styles.navigationLink)}>
                <span role="button" onClick={scroll.toAboutUsPanel}>About us</span>
              </div>
              <div className={classes("text-decoration-none ff-triakis fw-normal", styles.navigationLink)}>
                <span role="button" onClick={scroll.toGetInTouchPanel}>Get in touch</span>
              </div>
            </Stack>
          </div>
          <div className={classes("d-flex flex-column ff-triakis fw-normal", styles.contacts)}>
            <span className="mb-1 text-white">
              ShapeShift AI LTD
              <br />
              128 City Road
              <br />
              EC1V 2NX
              <br />
              Great Britain, London
            </span>
            <a href="mailto:manager@shapeshift.ai" className="text-white text-decoration-none mt-2 mb-1">
              manager@shapeshift.ai
            </a>
            <a href="mailto:tech@shapeshift.ai" className="text-white text-decoration-none mt-2 mb-1">
              tech@shapeshift.ai
            </a>
          </div>
        </div>
        <div className={classes("d-flex", styles.second)}>
          <div className={classes("flex-grow-1 d-flex justify-content-between align-items-center", styles.rights)}>
          <span className={classes("text-white ff-triakis fw-normal", styles.text)}>
            All rights reserved {year}<br />Designed by ShapeShift.AI
          </span>
            <span className="text-white ff-triakis fw-normal fs-16">Find Us:</span>
          </div>
          <div className="d-flex">
            <NavigationLink
              to=""
              className={classes("h-100 flex-grow-1 fs-6", styles.link)}
              label="/ln"
            />
            <NavigationLink
              to=""
              className={classes("h-100 flex-grow-1 fs-6", styles.link, styles.central)}
              label="/fb"
            />
            <NavigationLink
              to=""
              className={classes("h-100 flex-grow-1 fs-6", styles.link)}
              label="/tw"
            />
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};
