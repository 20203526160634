import React from "react";
import { createBrowserRouter, createHashRouter, RouterProvider } from "react-router-dom";
import { Home, NotFound } from "../../pages";

interface IProps {}

const router = createHashRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export const Router: React.FC<IProps> = (props: IProps): React.ReactElement => {
  return <RouterProvider router={router} />;
};