export const useScroll = () => {
  const toTop = (): void => {
    try {
      window.scrollTo(0, 0);
    } catch (error) {
      console.log(error);
    }
  };

  const toElement = (selector: string): void => {
    try {
      const element = document.getElementById(selector);

      element?.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      console.log(error);
    }
  };

  const toServicesPanel = (): void => toElement("services-panel");

  const toTechnologiesPanel = (): void => toElement("technologies-panel");

  const toCaseStudyPanel = (): void => toElement("case_study-panel");

  const toAboutUsPanel = (): void => toElement("about_us-panel");

  const toGetInTouchPanel = (): void => toElement("get_in_touch-panel");

  return {
    toTop,
    toElement,
    toServicesPanel,
    toTechnologiesPanel,
    toCaseStudyPanel,
    toAboutUsPanel,
    toGetInTouchPanel,
  };
};
