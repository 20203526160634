import React, { CSSProperties } from "react";
import { classes } from "../../utils";
import styles from "./NavigationLink.module.scss";

interface IProps {
  to?: string;
  label?: string;
  className?: string;
  style?: CSSProperties;
  active?: boolean;
  target?: "_blank";
  onClick?: () => void;
}

export const NavigationLink: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { to, label, className, style, active, target, onClick } = props;

  return (
    <a
      href={to}
      className={classes(
        "d-inline-flex justify-content-center align-items-center text-decoration-none ff-triakis fw-normal p-3",
        styles.button,
        active && styles.active,
        className,
      )}
      role="button"
      style={style}
      target={target}
      onClick={onClick}
    >{label}</a>
  );
};
