import React, { FC, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { classes } from "../../utils";
import styles from "./CaseStudyPanel.module.scss";
import arrowDown from "./arrow-down.svg";
import image from "./image.png";
import achievement from "./achivment1.png";

interface IProps {}

interface Item {
  path: string;
  type: string;
  languages: string[];
  challenge: string;
  solution: {
    text: string;
    list: string[];
  };
  image: string;
  achievements: string[];
}

const DATA: Item[] = [
  {
    path: "/CoinUp",
    type: "E-commerce",
    languages: [ "SWIFT", "KOTLIN", "JAVA", "REACT JS" ],
    challenge: "One of the largest food retailers needed to automate inspection and maintenance processes in their stores and update their troubleshooting reports. Retail companies have notoriously complex requirements when it comes to ongoing maintenance, and manual inspections can limit the ability to report and verify current conditions.",
    solution: {
      text: "ShapeShift developed a powerful solution combining cross-device technologies and business process automation:",
      list: [
        "Application for hand-held devices to simplify picture-taking and checklist marking during inspection",
        "Web-application for administrators and other employees responsible for troubleshooting, generating reports, and scheduling re-checks",
        "Integration with KPI corporate management system",
      ],
    },
    image,
    achievements: [],
  },
  {
    path: "/GG Bank",
    type: "Banking",
    languages: [ "SWIFT", "KOTLIN", "JAVA", "REACT JS" ],
    challenge: "One of the largest food retailers needed to automate inspection and maintenance processes in their stores and update their troubleshooting reports. Retail companies have notoriously complex requirements when it comes to ongoing maintenance, and manual inspections can limit the ability to report and verify current conditions.",
    solution: {
      text: "ShapeShift developed a powerful solution combining cross-device technologies and business process automation:",
      list: [
        "Application for hand-held devices to simplify picture-taking and checklist marking during inspection",
        "Web-application for administrators and other employees responsible for troubleshooting, generating reports, and scheduling re-checks",
        "Integration with KPI corporate management system",
      ],
    },
    image,
    achievements: [ achievement, achievement ],
  },
  {
    path: "/SaveWave",
    type: "Banking",
    languages: [ "SWIFT", "KOTLIN", "JAVA", "REACT JS" ],
    challenge: "One of the largest food retailers needed to automate inspection and maintenance processes in their stores and update their troubleshooting reports. Retail companies have notoriously complex requirements when it comes to ongoing maintenance, and manual inspections can limit the ability to report and verify current conditions.",
    solution: {
      text: "ShapeShift developed a powerful solution combining cross-device technologies and business process automation:",
      list: [
        "Application for hand-held devices to simplify picture-taking and checklist marking during inspection",
        "Web-application for administrators and other employees responsible for troubleshooting, generating reports, and scheduling re-checks",
        "Integration with KPI corporate management system",
      ],
    },
    image,
    achievements: [ achievement, achievement, achievement, achievement ],
  },
  {
    path: "/GG Bank",
    type: "Banking",
    languages: [ "SWIFT", "KOTLIN", "JAVA", "REACT JS" ],
    challenge: "One of the largest food retailers needed to automate inspection and maintenance processes in their stores and update their troubleshooting reports. Retail companies have notoriously complex requirements when it comes to ongoing maintenance, and manual inspections can limit the ability to report and verify current conditions.",
    solution: {
      text: "ShapeShift developed a powerful solution combining cross-device technologies and business process automation:",
      list: [
        "Application for hand-held devices to simplify picture-taking and checklist marking during inspection",
        "Web-application for administrators and other employees responsible for troubleshooting, generating reports, and scheduling re-checks",
        "Integration with KPI corporate management system",
      ],
    },
    image,
    achievements: [],
  },
];

export const CaseStudyPanel: FC<IProps> = (props: IProps): React.ReactElement => {
  const [ active, setActive ] = useState(-1);

  const handleClick = (index: number): void => {
    setActive((prevState) => {
      return prevState === index ? -1 : index;
    });
  };

  return (
    <section id="case_study-panel" className={classes("position-relative d-flex flex-column", styles.container)}>
      <div className={styles.bg}>
        <div className={styles.grid}>
          <Container className={classes(styles.top)} fluid>
            <Row>
              <Col xs={12} lg={5} className="p-0">
                <h3 className={classes("text-title ms-4 mb-0", styles.title)}>
                  /Case study
                </h3>
              </Col>
              <Col xs={12} lg={7} className="p-0">
                <div className={classes("mt-2 mt-lg-0 ms-4 ms-lg-0", styles.text)}>
                  <span className="d-inline-flex flex-column ff-poppins text-white fw-normal fs-10 ls-1 px-3 px-lg-0">
                    <span>Real-world solutions for real-world problems:</span>
                    <span>Check out our successful case studies</span>
                  </span>
                </div>
              </Col>
            </Row>
            <span className="invisible"></span>
          </Container>
          <Container className={styles.table} fluid>
            {DATA.map((item, index) => (
              <Row
                key={index}
                className={classes(styles.item, active === index && styles.selected, index > 0 && styles.notFirst)}
                role="button"
                onClick={() => handleClick(index)}
              >
                <Col
                  xs={3}
                  md={3}
                  lg={2}
                  xl={1}
                  xxl={1}
                  className="d-flex justify-content-center align-items-start border"
                >
                  <img className={styles.arrow} src={arrowDown} alt="arrow" />
                </Col>
                <Col
                  xs={9}
                  md={9}
                  lg={10}
                  xl={11}
                  xxl={11}
                  className="position-relative p-0 border-top border-end border-bottom border-start-0"
                >
                  <div className={classes(
                    "position-absolute w-100 h-100 d-flex d-lg-flex",
                    active === index ? "d-none" : "d-flex",
                    styles.inner,
                  )} />
                  <div className={classes(
                    "d-flex flex-wrap justify-content-between align-items-start",
                    styles.content,
                  )}>
                    <div className={classes("d-flex flex-column", styles.rowPath)}>
                      <span className={classes("text-white ff-triakis fw-normal")}>{item.path}</span>
                      {active === index && (
                        <div className={classes("d-none d-lg-flex flex-column", styles.data)}>
                          <span className="text-white ff-triakis fw-normal fs-4">Challenge</span>
                          <span className="text-white ff-poppins fw-normal fs-12 ls-1 mt-4">{item.challenge}</span>
                        </div>
                      )}
                    </div>
                    <div className={classes("d-none d-lg-flex flex-column", styles.rowType)}>
                      <span className="text-white ff-triakis fw-normal">{item.type}</span>
                      {active === index && (
                        <div className={classes("d-flex flex-column", styles.data)}>
                          <span className="text-white ff-triakis fw-normal fs-4">Solution</span>
                          <span className="text-white ff-poppins fw-normal fs-12 ls-1 mt-4">{item.solution.text}</span>
                          <div className="d-flex flex-column ps-2">
                            {item.solution.list.map((element, key) => (
                              <span
                                key={key}
                                className={classes(
                                  "position-relative text-white ff-poppins fw-normal ls-1 fs-10 mt-4 ms-1",
                                  styles.element,
                                )}
                              >{element}</span>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={classes("d-none d-lg-flex flex-column", styles.rowLanguages)}>
                      <span className="text-white ff-triakis fw-normal text-uppercase">
                        {item.languages.join(", ")}
                      </span>
                      {active === index && (
                        <div className={classes("d-flex", styles.data)}>
                          <img src={item.image} alt="image" />
                        </div>
                      )}
                    </div>
                    <div className={classes("d-flex d-lg-none flex-column")}>
                      <span className={classes("ff-triakis fw-normal text-white mb-1", styles.mobileRowElement)}>
                        {item.type}
                      </span>
                      <span className={classes("ff-triakis fw-normal text-white mt-2", styles.mobileRowElement)}>
                        {item.languages.join(", ")}
                      </span>
                    </div>
                    {active === index && !!item.achievements.length && (
                      <div className="d-none d-lg-flex mt-4" style={{ width: "100%" }}>
                        {item.achievements.map((img, key) => (
                          <img key={key} className={styles.achievement} src={img} alt="achievement" />
                        ))}
                      </div>
                    )}
                  </div>
                </Col>
                {active === index && (
                  <Col xs={12} className="position-relative d-block d-lg-none border-bottom">
                    <div className={classes("position-absolute w-100 h-100 d-flex mx-0 px-0", styles.mobileInner)} />
                    <Row>
                      <Col xs={4} className="offset-1 px-0">
                        <span className={classes(
                          "ff-triakis fw-normal text-white",
                          styles.mobileChallengeSolutionTitle,
                        )}>Challenge</span>
                      </Col>
                      <Col xs={6}>
                        <span className={classes(
                          "ff-poppins fw-normal text-white",
                          styles.mobileChallengeSolutionText,
                        )}>{item.challenge}</span>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col xs={4} className="offset-1">
                        <span className={classes(
                          "ff-triakis fw-normal text-white",
                          styles.mobileChallengeSolutionTitle,
                        )}>Solution</span>
                      </Col>
                      <Col xs={6}>
                        <span className={classes(
                          "ff-poppins fw-normal text-white",
                          styles.mobileChallengeSolutionText,
                        )}>{item.solution.text}</span>
                        <div className="d-flex flex-column ps-2">
                          {item.solution.list.map((element, key) => (
                            <span
                              key={key}
                              className={classes(
                                "position-relative text-white ff-poppins fw-normal mt-4 ms-1",
                                styles.mobileElement,
                              )}
                            >{element}</span>
                          ))}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={11} className="offset-1">
                        <img className="mt-5" src={item.image} style={{ width: "100%" }} alt="image" />
                      </Col>
                    </Row>
                    {!!item.achievements.length && (
                      <Row>
                        {item.achievements.map((img, key) => (
                          <Col key={key} className={key % 2 === 0 ? "offset-1 mt-4" : "mt-4"} xs={5}>
                            <img className={styles.achievement} src={img} alt="achievement" />
                          </Col>
                        ))}
                      </Row>
                    )}
                  </Col>
                )}
              </Row>
            ))}
          </Container>
          <div className={classes("position-absolute w-100", styles.gradient)} />
        </div>
      </div>
    </section>
  );
};
