import React from "react";
import { Modal } from "react-bootstrap";
import styles from "./InfoModal.module.scss";
import { classes } from "../../utils";

interface IProps {
  visible: boolean;
  title?: string;
  description?: string;
  onHide?: () => void;
}

export const InfoModal: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { visible, title, description, onHide } = props;

  return (
    <Modal
      className={styles.container}
      contentClassName={styles.modal}
      show={visible}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="text-white ff-triakis">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-white ff-triakis">{description}</p>
      </Modal.Body>
      <Modal.Footer>
        <div className={classes("px-4 py-2 ff-triakis", styles.button)} role="button" onClick={onHide}>/Close</div>
      </Modal.Footer>
    </Modal>
  );
};