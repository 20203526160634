import React from "react";
import { GetInTouchLink } from "../GetInTouchLink";
import { NavigationLink } from "../NavigationLink";
import { classes } from "../../utils";
import styles from "./SocialPanel.module.scss";

interface IProps {}

export const SocialPanel: React.FC<IProps> = (props: IProps): React.ReactElement => {
  return (
    <section className={classes("d-flex flex-column-reverse flex-lg-row", styles.container)}>
      <div className={classes("px-0 flex-grow-1", styles.item, styles.getInTouch)}>
        <GetInTouchLink className="h-100 fs-4" />
      </div>
      <div
        className={classes("d-flex justify-content-start align-items-center px-0 flex-grow-1",
          styles.item,
          styles.paragraph,
        )}
      >
        <div className={classes("text-white ff-poppins fw-normal fs-10 text-white px-3 ls-1", styles.text)}>
          <span className="d-inline d-lg-none">We are small, we are individual and we are flexible!<br /></span>
          <span>
            We are not fixated on using old technologies and system designs, we can experiment with our small size and
            create really cool systems.
          </span>
        </div>
      </div>
      <div className={classes("d-none d-lg-flex px-0", styles.navigation, styles.item)}>
        <NavigationLink
          to=""
          className={classes("h-100 flex-grow-1 fs-6", styles.link)}
          label="/ln"
        />
        <NavigationLink
          to=""
          className={classes("h-100 flex-grow-1 fs-6", styles.link, styles.central)}
          label="/fb"
        />
        <NavigationLink
          to=""
          className={classes("h-100 flex-grow-1 fs-6", styles.link)}
          label="/tw"
        />
      </div>
    </section>
  );
};
