import React, { CSSProperties } from "react";
import { classes } from "../../utils";
import { Footer, Header } from "../../components";
import styles from "./Landing.module.scss";

interface IProps {
  main?: {
    className?: string;
    style?: CSSProperties;
  };
  children?: React.ReactNode | React.ReactNode[];
}

export const Landing: React.FC<IProps> = ({ main, children }: IProps): React.ReactElement => {
  return (
    <div className="position-relative d-flex flex-column justify-content-start align-items-stretch">
      <Header />
      <main
        className={classes(
          "d-flex flex-column justify-content-start align-items-stretch",
          styles.main,
          main?.className,
        )}
        style={main?.style}
      >{children}</main>
      <Footer />
    </div>
  );
};