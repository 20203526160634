import React, { CSSProperties, useState } from "react";
import { classes } from "../../utils";
import styles from "./Textarea.module.scss";

interface IProps {
  value?: string;
  placeholder?: string;
  className?: string;
  style?: CSSProperties;
  onChange?: (value: string) => void;
}

export const Textarea: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { className, style, value, placeholder, onChange } = props;

  const [ focused, setFocused ] = useState<boolean>(false);

  const handleChange = (event: any): void => {
    if (!!onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <textarea
      value={value}
      placeholder={placeholder}
      className={classes("ff-poppins fw-normal text-white", styles.textarea, focused && styles.focused, className)}
      style={style}
      onChange={handleChange}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    />
  );
};