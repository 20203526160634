import React, { CSSProperties, useState } from "react";
import { classes } from "../../utils";
import styles from "./Input.module.scss";

interface IProps {
  value?: string;
  placeholder?: string;
  type?: "text" | "email" | "password";
  className?: string;
  style?: CSSProperties;
  valid?: boolean;
  onChange?: (value: string) => void;
}

export const Input: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { type = "text", className, style, value, placeholder, valid = false, onChange } = props;

  const [ focused, setFocused ] = useState<boolean>(false);

  const handleChange = (event: any): void => {
    if (!!onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <div
      className={classes(
        "d-flex justify-content-start align-items-center",
        styles.container,
        focused && styles.focused,
        className,
      )}
    >
      <input
        type={type}
        value={value}
        placeholder={placeholder}
        className={classes("flex-grow-1 ff-poppins text-white fw-normal ls-1", styles.input)}
        style={style}
        onChange={handleChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      {!!value && (
        <div className={classes("mx-1", styles.indicator, valid && styles.success, !valid && styles.error)} />
      )}
    </div>
  );
};