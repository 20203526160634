import React, { useState } from "react";
import { NavigationLink } from "../NavigationLink";
import { GetInTouchLink } from "../GetInTouchLink";
import { Stack } from "react-bootstrap";
import { useScroll } from "../../hooks";
import logo from "./logo.svg";
import { classes } from "../../utils";
import styles from "./Header.module.scss";

interface IProps {}

export const Header: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const scroll = useScroll();
  const [ isVisibleMenu, setIsVisibleMenu ] = useState(false);

  const handleClickToLogo = (): void => {
    setIsVisibleMenu(false);
    scroll.toTop();
  };

  const handleClickToMenu = (): void => {
    setIsVisibleMenu((prevState) => !prevState);
  };

  const handleClickToServicesPanel = (): void => {
    setIsVisibleMenu(false);
    scroll.toServicesPanel();
  };

  const handleClickToTechnologiesPanel = (): void => {
    setIsVisibleMenu(false);
    scroll.toTechnologiesPanel();
  };

  const handleClickToCaseStudyPanel = (): void => {
    setIsVisibleMenu(false);
    scroll.toCaseStudyPanel();
  };

  const handleClickToAboutUsPanel = (): void => {
    setIsVisibleMenu(false);
    scroll.toAboutUsPanel();
  };

  const handleClickToGetInTouchPanel = (): void => {
    setIsVisibleMenu(false);
    scroll.toGetInTouchPanel();
  };

  return (
    <header
      className={classes("position-fixed w-100 d-flex justify-content-between align-items-center", styles.header)}
    >
      <div className={classes("h-100 d-flex justify-content-between align-items-center", styles.wrapper)}>
        <img src={logo} alt="logo" role="button" onClick={handleClickToLogo} />
      </div>
      <NavigationLink
        className={classes("h-100 fs-4", styles.button)}
        label="/Menu"
        active={isVisibleMenu}
        onClick={handleClickToMenu}
      />
      <GetInTouchLink className={classes("h-100 d-none d-lg-flex fs-4", styles.button)} />
      {isVisibleMenu && (
        <React.Fragment>
          <Stack direction="vertical" className={classes("position-fixed d-none d-lg-flex", styles.desktopMenu)}>
            <div className={classes("d-flex flex-column align-items-start", styles.routes)}>
              <div
                className={classes("ff-triakis text-decoration-none", styles.route)}
                role="button"
                onClick={handleClickToServicesPanel}
              >/Services</div>
              <div
                className={classes("ff-triakis text-decoration-none mt-40", styles.route)}
                role="button"
                onClick={handleClickToTechnologiesPanel}
              >/Technologies</div>
              <a
                className={classes("ff-triakis text-decoration-none mt-40", styles.route)}
                role="button"
                onClick={handleClickToCaseStudyPanel}
              >/Case study</a>
              <a
                className={classes("ff-triakis text-decoration-none mt-40", styles.route)}
                role="button"
                onClick={handleClickToAboutUsPanel}
              >/About Us</a>
            </div>
            <div className={classes("d-flex justify-content-end align-items-start", styles.links)}>
              <div className={classes("flex-grow-1", styles.empty)}></div>
              <NavigationLink
                to=""
                className={classes("h-100 flex-grow-1 fs-24", styles.link)}
                label="/ln"
              />
              <NavigationLink
                to=""
                className={classes("h-100 flex-grow-1 fs-24", styles.link, styles.central)}
                label="/fb"
              />
              <NavigationLink
                to=""
                className={classes("h-100 flex-grow-1 fs-24", styles.link)}
                label="/tw"
              />
            </div>
          </Stack>
          <Stack
            direction="vertical"
            className={classes(
              "position-fixed w-100 d-flex d-lg-none justify-content-start align-items-stretch",
              styles.mobileMenu,
            )}
          >
            <div className={classes("h-100 flex-grow-1 d-flex flex-column align-items-center", styles.routes)}>
              <div
                className={classes("ff-triakis fs-48 text-decoration-none", styles.route)}
                role="button"
                onClick={handleClickToServicesPanel}
              >/Services</div>
              <div
                className={classes("ff-triakis fs-48 text-decoration-none mt-40", styles.route)}
                role="button"
                onClick={handleClickToTechnologiesPanel}
              >/Technologies</div>
              <div
                className={classes("ff-triakis fs-48 text-decoration-none mt-40", styles.route)}
                role="button"
                onClick={handleClickToCaseStudyPanel}
              >/Case study</div>
              <div
                className={classes("ff-triakis text-decoration-none mt-40", styles.route)}
                role="button"
                onClick={handleClickToAboutUsPanel}
              >/About Us</div>
            </div>
            <GetInTouchLink
              className={classes("fs-24", styles.mobileGetInTouchLink)}
              onClick={handleClickToGetInTouchPanel}
            />
            <Stack direction="horizontal">
              <NavigationLink
                to=""
                className={classes("h-100 flex-grow-1 fs-24", styles.mobileLink)}
                label="/ln"
                target="_blank"
              />
              <NavigationLink
                to=""
                className={classes("h-100 flex-grow-1 fs-24", styles.mobileLink, styles.central)}
                label="/fb"
                target="_blank"
              />
              <NavigationLink
                to=""
                className={classes("h-100 flex-grow-1 fs-24", styles.mobileLink)}
                label="/tw"
                target="_blank"
              />
            </Stack>
          </Stack>
        </React.Fragment>
      )}
    </header>
  );
};
