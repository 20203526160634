import React, { useState } from "react";
import { classes } from "../../utils";
import { Col, Container, Row } from "react-bootstrap";
import { NavigationLink } from "../NavigationLink";
import styles from "./TechnologiesPanel.module.scss";

import go from "./go.png";
import vue from "./vue.png";
import python from "./python.png";
import node from "./node.png";

interface IProps {}

const DATA: Array<{ tab: string, label: string, text: string, image: string }> = [
  {
    tab: "-GoLang",
    label: "/GoLang",
    text: "In the realm of cutting-edge software development, Golang (Go) is the cool, young rebel on the block. It's not just your average programming language; it's a game-changer for MVPs and dev. And did we mention, it's fast? Like, really fast.  With Golang, you can build MVPs that are lightning-quick. Its clean syntax and compatibility with containerization technologies like Docker make it a must-have for any modern startup.",
    image: go,
  },
  {
    tab: "-Vue",
    label: "/Vue",
    text: "Vue.js is the game-changer for building dynamic, engaging user interfaces. With its reactive data binding and component-based architecture, Vue.js makes it easy to develop complex UIs while ensuring lightning-fast performance.",
    image: vue,
  },
  {
    tab: "-Python",
    label: "/Python",
    text: "Python is a widely-used, high-level programming language known for its simplicity, readability and versatility. It is used in a variety of fields, including web development, data science, machine learning, scientific computing, and artificial intelligence. Python is also commonly used for scripting and automation, as well as in the development of desktop and mobile applications. Additionally, many popular libraries and frameworks such as Django, Flask, and TensorFlow are built using Python. Overall, Python's simplicity and flexibility make it a go-to choice for developers and data scientists looking to build robust, efficient and maintainable software.",
    image: python,
  },
  {
    tab: "-JS (Node)",
    label: "/NodeJS",
    text: "Node.js is a powerhouse of scalable, high-performance server-side applications. With its event-driven, non-blocking I/O model and vast ecosystem of third-party packages, Node.js empowers developers to build cutting-edge solutions that can handle thousands of concurrent connections with ease.",
    image: node,
  },
];

export const TechnologiesPanel: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const [ active, setActive ] = useState<number>(0);

  return (
    <section id="technologies-panel" className={classes("d-flex flex-column", styles.container)}>
      <Container className={classes(styles.top)} fluid>
        <Row>
          <Col xs={12} lg={5} className="p-0">
            <h3 className={classes("text-title ms-4 mb-0", styles.title)}>
              /Technologies
            </h3>
          </Col>
          <Col xs={12} lg={7} className="p-0">
            <div className={classes("mt-2 mt-lg-0 ms-4 ms-lg-0", styles.text)}>
              <span className="d-inline-flex flex-column ff-poppins text-white fw-normal fs-10 ls-1 px-3 px-lg-0">
                <span>Cutting-edge technologies powering</span>
                <span>our innovative products</span>
              </span>
            </div>
          </Col>
        </Row>
        <span className="invisible"></span>
      </Container>
      <Container fluid>
        <Row className={styles.tabs}>
          {DATA.map((item, index) => (
            <Col key={index} xs={6} lg={3} className="p-0">
              <NavigationLink
                label={item.tab}
                className={classes("w-100 h-100 fs-24 text-white", styles.tab, active === index && "text-warning")}
                active={index === active}
                onClick={() => setActive(index)}
              />
            </Col>
          ))}
        </Row>
        <Row className={classes("position-relative", styles.content)}>
          <div className={classes("position-absolute w-100 h-100", styles.contentBackground)} />
          <Col xs={12} lg={3} className="d-flex justify-content-start justify-content-lg-end align-items-start p-0 px-2 px-lg-0">
            <span className="ff-triakis fw-normal text-white fs-32 me-5">{DATA[active].label}</span>
          </Col>
          <Col xs={12} lg={6} className="p-0">
            <div className="px-4 px-lg-0">
              <span className={classes("ff-poppins fw-normal fs-12 ls-1 text-white", styles.contentText)}>
                {DATA[active].text}
              </span>
            </div>
          </Col>
          <Col xs={12} lg={3} className="d-flex justify-content-center align-items-center p-0 mt-5 mt-lg-0">
            <img className={styles.contentImage} src={DATA[active].image} alt={DATA[active].label.toLowerCase()} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
