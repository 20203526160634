import React from "react";
import { classes } from "../../utils";
import styles from "./GetInTouchLink.module.scss";
import arrowLeft from "./arrow-left.svg";
import arrowRight from "./arrow-right.svg";
import { useScroll } from "../../hooks";

interface IProps {
  className?: string;
  classNames?: { div?: string, span?: string, img?: string };
  onClick?: () => void;
}

export const GetInTouchLink: React.FC<IProps> = ({ className, classNames, onClick }: IProps): React.ReactElement => {
  const scroll = useScroll();

  const handleClick = (): void => {
    if (!!onClick) {
      onClick();
    } else {
      scroll.toGetInTouchPanel();
    }
  };

  return (
    <div
      className={classes(
        "h-100 d-flex justify-content-center align-items-center text-decoration-none p-3",
        styles.button,
        className,
        classNames?.div,
      )}
      role="button"
      onClick={handleClick}
    >
      <img className={classNames?.img} src={arrowLeft} alt="arrow-left" />
      <span className={classes("ff-triakis fw-normal mx-2", classNames?.span)}>Get In Touch</span>
      <img className={classNames?.img} src={arrowRight} alt="arrow-right" />
    </div>
  );
};
