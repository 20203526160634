import React from "react";
import Lottie from "lottie-react";
import { classes } from "../../utils";
import styles from "./WelcomePanel.module.scss";
import fill from "./fill.svg";
import title from "./title.json";
import text1 from "./text-1.json";
import text2 from "./text-2.json";

interface IProps {}

export const WelcomePanel: React.FC<IProps> = (props: IProps): React.ReactElement => {
  return (
    <section className={classes("position-relative", styles.container)}>
      <div className={classes("position-absolute h-100 w-100", styles.backdrop)}>
        <video className={styles.video} width="100%" height="100%" autoPlay muted loop playsInline>
          <source src="./assets/media/welcome-bg.mp4" type="video/mp4" className="h-100 w-100" />
        </video>
      </div>
      <Lottie
        className={classes("position-absolute", styles.animatedText, styles.animatedText1)}
        animationData={text1}
        loop
        autoPlay
        muted
      />
      <Lottie
        className={classes("position-absolute", styles.animatedText, styles.animatedText2)}
        animationData={text2}
        loop
        autoPlay
        muted
      />
      <img className={classes("position-absolute w-100 d-flex d-lg-none", styles.fill)} src={fill} alt="fill" />
      <div className="position-relative">
        <Lottie className={styles.title} animationData={title} loop autoPlay muted />
        <span className={classes("d-inline-flex fw-normal text-white ff-poppins", styles.description)}>
          software agency for building fast & scalable MVPs
        </span>
      </div>
      <div className={classes("position-absolute h-100 d-none d-lg-flex", styles.divider)} />
    </section>
  );
};