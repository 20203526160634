import React, { FC, useCallback, useState } from "react";
import {Col, Container, Row} from "react-bootstrap";
import { GetInTouchLink } from "../GetInTouchLink";
import { Input } from "../Input";
import { Textarea } from "../Textarea";
import Lottie from "lottie-react";
import { classes } from "../../utils";
import styles from "./GetInTouchPanel.module.scss";
import text1 from "./text-1.json";
import text2 from "./text-2.json";
import future from "./future.json";

interface IProps {}

export const GetInTouchPanel: FC<IProps> = (props: IProps): React.ReactElement => {
  const [ name, setName ] = useState<string>("");
  const [ email, setEmail ] = useState<string>("");
  const [ phone, setPhone ] = useState<string>("");
  const [ comment, setComment ] = useState<string>("");

  const isValidName = useCallback(() => {
    return !!name.trim() && name.trim().length > 3;
  }, [ name ]);

  const isValidEmail = useCallback(() => {
    return !!email.trim() && email.trim().length > 5 && !!email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
  }, [ email ]);

  const isValidPhone = useCallback(() => {
    return !!phone.trim() && phone.trim().length > 6;
  }, [ phone ]);

  return (
    <section className={classes("d-flex flex-column", styles.container)}>
      <Container fluid>
        <Row>
          <Col xs={12} className={classes("px-0 position-relative", styles.videoWrap)}>
            <video className={styles.video} width="100%" height="100%" autoPlay muted loop playsInline>
              <source src="./assets/media/get-in-touch.mp4" type="video/mp4" className="h-100 w-100" />
            </video>
            <div
              className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center top-0 start-0"
            >
              <Lottie
                className={classes("position-absolute", styles.animatedText, styles.animatedText1)}
                animationData={text1}
                loop
                autoPlay
                muted
              />
              <Lottie
                className={classes("position-absolute", styles.animatedText, styles.animatedText2)}
                animationData={text2}
                loop
                autoPlay
                muted
              />
              <Lottie
                className={styles.animatedFuture}
                animationData={future}
                loop
                autoPlay
                muted
              />
            </div>
          </Col>
        </Row>
        <Row id="get_in_touch-panel">
          <Col xs={12} className={classes(styles.mt100, styles.titleContainer)}>
            <Container>
              <Row>
                <Col xs={12}>
                  <span className={classes(styles.title, "d-flex d-md-none text-title")}>
                    /Get in Touch with<br/>the Future:<br/>Contact Us Today
                  </span>
                  <span className={classes(styles.title, "d-none d-md-flex text-title")}>
                    /Get in Touch with the Future:<br/>Contact Us Today
                  </span>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col xs={12}>
            <Container>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <Col xs={12}>
                      <Input
                        type="text"
                        placeholder="Name"
                        valid={isValidName()}
                        value={name}
                        onChange={(value) => setName(value)}
                      />
                    </Col>
                    <Col xs={12} className={styles.mt18}>
                      <Input
                        type="email"
                        placeholder="Email"
                        valid={isValidEmail()}
                        value={email}
                        onChange={(value) => setEmail(value)}
                      />
                    </Col>
                    <Col xs={12} className={styles.mt18}>
                      <Input
                        type="text"
                        placeholder="Phone"
                        valid={isValidPhone()}
                        value={phone}
                        onChange={(value) => setPhone(value)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6} className="mt-4 mt-md-0">
                  <Textarea
                    placeholder="Your Comment"
                    className={classes("w-100 h-100 text-white fw-normal fs-16 ls-1", styles.textarea)}
                    value={comment}
                    onChange={(value) => setComment(value)}
                  />
                </Col>
              </Row>
              <Row className={classes(styles.mt40, styles.mb150)}>
                <Col xs={12}>
                  <GetInTouchLink classNames={{ div: styles.height80px, span: "fs-24" }} />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
