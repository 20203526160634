import React, { FC } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { classes } from "../../utils";
import styles from "./AboutUsPanel.module.scss";
import map from "./map.png";

interface IProps {}

export const AboutUsPanel: FC<IProps> = (props: IProps): React.ReactElement => {
  return (
    <section id="about_us-panel" className={classes("d-flex flex-column", styles.container)}>
      <Container className={classes(styles.top)} fluid>
        <Row>
          <Col xs={12} lg={5} className="p-0">
            <h3 className={classes("text-title ms-4 mb-0", styles.title)}>
              /About Us
            </h3>
          </Col>
          <Col xs={12} lg={7} className="p-0">
            <div className={classes("mt-2 mt-lg-0 ms-4 ms-lg-0", styles.text)}>
              <span className="d-inline-flex flex-column ff-poppins text-white fw-normal fs-10 ls-1 px-3 px-lg-0">
                <span>We are small, we are individual</span>
                <span>and we are flexible!</span>
              </span>
            </div>
          </Col>
        </Row>
        <span className="invisible"></span>
      </Container>
      <Container fluid className={classes("position-relative", styles.bg)}>
        <Row className={classes("position-relative d-flex flex-column-reverse flex-lg-row", styles.content)}>
          <Col
            xs={12}
            lg={4}
            className={classes("d-flex flex-column justify-content-center align-items-center p-0", styles.bgMobile)}
          >
            <img className={styles.mapItem} src={map} alt="map" />
            <Row className={classes("w-100 mt-5", styles.addressItem)}>
              <Col xs={4} lg={3} className="offset-0 offset-md-2">
                <span className={classes("ff-triakis fw-lighter text-white", styles.location)}>/location:</span>
              </Col>
              <Col xs={4} lg={3} className="offset-0 offset-md-2">
                <Stack direction="vertical">
                  <span className={classes("ff-triakis fw-normal text-white text-nowrap ls-1", styles.addressLine)}>
                    ShapeShift AI LTD
                  </span>
                  <span className={classes("ff-triakis fw-normal text-white text-nowrap ls-1", styles.addressLine)}>
                    128 City Road
                  </span>
                  <span className={classes("ff-triakis fw-normal text-white text-nowrap ls-1", styles.addressLine)}>
                    EC1V 2NX
                  </span>
                  <span className={classes("ff-triakis fw-normal text-white text-nowrap ls-1", styles.addressLine)}>
                    Great Britain, London
                  </span>
                </Stack>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={8} className="p-0">
            <Stack direction="vertical" className={classes("h-100", styles.descriptionContainer)}>
              <p className={classes("ff-poppins fw-normal fs-12 ls-1 text-white m-0", styles.description)}>
                Shapeshift is a software development agency that specializes in creating innovative and cutting-edge
                solutions for businesses of all sizes. Our team is made up of experienced developers, designers, and
                project managers who work together to deliver high-quality software that meets the unique needs of each
                of our clients.
              </p>
              <p className={classes("ff-poppins fw-normal fs-12 ls-1 text-white m-0 mt-3", styles.description)}>
                We understand that every business is different, and we take the time to get to know our clients and
                their specific needs in order to create customized solutions that will help them achieve their goals.
                Whether you need a new website, a mobile app, or a complex software system, we have the expertise and
                experience to make it happen.
              </p>
              <p className={classes("ff-poppins fw-normal fs-12 ls-1 text-white mt-3", styles.description)}>
                At Shapeshift, we are committed to staying on the cutting edge of technology and using the latest tools
                and techniques to deliver the best possible results for our clients. We believe in transparent and open
                communication, and we work closely with our clients throughout the entire development process to ensure
                that their needs are met and that they are completely satisfied with the final product.
              </p>
              <p className={classes("ff-poppins fw-normal fs-12 ls-1 text-white mt-3", styles.description)}>
                If you're looking for a reliable and experienced software development agency that can help you take
                your business to the next level, look no further than Shapeshift. Contact us today to learn more about
                how we can help you achieve your goals.
              </p>
            </Stack>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
