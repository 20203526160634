import React, { useState } from "react";
import { Landing } from "../../layouts";
import {
  AboutUsPanel,
  CaseStudyPanel,
  GetInTouchPanel,
  ServicesPanel,
  SocialPanel,
  TechnologiesPanel,
  WelcomePanel,
} from "../../components";
import { InfoModal } from "../../modals";

interface IProps {}

export const Home: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const [ isVisibleSuccessModal, setIsVisibleSuccessModal ] = useState<boolean>(false);

  return (
    <Landing>
      <WelcomePanel />
      <SocialPanel />
      <ServicesPanel />
      <TechnologiesPanel />
      <CaseStudyPanel />
      <AboutUsPanel />
      <GetInTouchPanel />
      <InfoModal
        visible={isVisibleSuccessModal}
        title="Success"
        description="Your data sent!"
        onHide={() => setIsVisibleSuccessModal(false)}
      />
    </Landing>
  );
};