import React, { FC, useEffect, useState } from "react";
import { Col, Container, Fade, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import { classes } from "../../utils";
import styles from "./ServicesPanel.module.scss";

import arrow from "./arrow.svg";
import line1 from "./line-1.svg";
import line2 from "./line-2.svg";
import bg from "./bg.jpg";
import city from "./city.png";
import train from "./train.png";
import building from "./building.png";

import mvpAnimation from "./mvp.animation.json";
import fintechAnimation from "./fintech.animation.json";
import sassAnimation from "./saas.animation.json";

interface IProps {}

const DATA: Array<{ lottie: { ref: any, animation: any }, path: string, description: string[], list: string[] }> = [
  {
    lottie: {
      ref: React.createRef(),
      animation: mvpAnimation,
    },
    path: "/MVP",
    description: [
"Fast, Bleeding-Edge, and Built to Last.",
"\n" +
"As a fellow startup, we understand the importance of launching your Minimum Viable Product (MVP) quickly, efficiently, and with the latest technologies. We're not afraid to push boundaries and embrace the cutting-edge, ensuring your MVP is not only powerful but also built to last in the rapidly evolving tech landscape.\n",
"\n" +
"Our team thrives on the excitement of new challenges, and we're dedicated to delivering exceptional results within your budget. We'll leverage startup credits and innovative technologies to save you thousands while maintaining the highest quality standards.\n" +
"\n" +
"Partner with us, and let's revolutionize the tech world together."    ],
    list: [
      "Discovery & planning",
      "Design & development",
      "Testing & QA Deployment",
      "Maintenance & support",
      "Analytics & improvement",
    ],
  },
  {
    lottie: {
      ref: React.createRef(),
      animation: fintechAnimation,
    },
    path: "/Fintech",
    description: [
"We are the Fintech rebels, pushing the boundaries of what's possible in the world of finance. Our team of cyberpunk warriors is dedicated to creating innovative, secure, and user-friendly solutions that will change the way people interact with money.",
"\n" +
"With a focus on blockchain, cryptocurrency, and AI-driven technologies, we'll help you stay ahead of the competition and carve out your own niche in the financial revolution. Our bleeding-edge solutions are tailored to your specific needs, ensuring you're not just keeping up with the times but setting the pace."
    ],
    list: [
      "Discovery & planning",
      "Design & development",
      "Testing & QA Deployment",
      "Maintenance & support",
      "Analytics & improvement",
    ],
  },
  {
    lottie: {
      ref: React.createRef(),
      animation: sassAnimation,
    },
    path: "/SaaS",
    description: [
"At our SaaS development consultancy, we're not just software developers; we're architects of the digital future. Our team of cyberpunk visionaries is dedicated to creating powerful, scalable, and user-friendly software solutions that will propel your business to new heights.",
"\n" +
"From ideation to deployment, we'll work closely with you to design and develop a custom SaaS platform that meets your unique needs and exceeds your expectations. With a focus on the latest technologies and trends, we'll ensure your SaaS solution is not only cutting-edge but built to last in an ever-evolving digital landscape.",
"\n" +
"Together, we'll shape the future of SaaS development and push the boundaries of what's possible."
    ],
    list: [
      "Discovery & planning",
      "Design & development",
      "Testing & QA Deployment",
      "Maintenance & support",
      "Analytics & improvement",
    ],
  },
];

interface IRowDescriptionProps {
  data: string[];
}

const RowDescription: FC<IRowDescriptionProps> = ({ data }: IRowDescriptionProps): React.ReactElement => {
  return (
    <React.Fragment>
      {data.map((text, index) => (
        <p key={index} className={classes("text-white ff-poppins fw-normal fs-12 ls-1 my-0", index !== 0 && "mt-3")}>
          {text}
        </p>
      ))}
    </React.Fragment>
  );
};

interface IRowListProps {
  data: string[];
  className?: string;
}

const RowList: FC<IRowListProps> = ({ data, className }: IRowListProps): React.ReactElement => {
  return (
    <React.Fragment>
      {data.map((text, index) => (
        <span key={index} className={classes("text-white ff-poppins fw-normal fs-10 ls-1", className)}>{text}</span>
      ))}
    </React.Fragment>
  );
};

export const ServicesPanel: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const [ active, setActive ] = useState<number>(-1);
  const [ fadeAnimation, setFadeAnimation ] = useState(DATA.map((item, index) => true));

  const handleClick = (index: number): void => {
    if (index > -1) {
      DATA.forEach((item) => item.lottie.ref?.current.setSpeed(2));

      if (index !== active) {
        // open
        if (active > -1) {
          DATA[active].lottie.ref?.current.setDirection(1);
          DATA[active].lottie.ref?.current.goToAndPlay(0, true);
        }

        DATA[index].lottie.ref?.current.setDirection(-1);
        DATA[index].lottie.ref?.current.goToAndPlay(20, true);
      } else {
        // close
        DATA[active].lottie.ref?.current.setDirection(1);
        DATA[active].lottie.ref?.current.goToAndPlay(0, true);
      }
    }

    setFadeAnimation(DATA.map((item, ind) => index !== ind));

    setTimeout(() => {
      setActive((prevState) => {
        return prevState === index ? -1 : index;
      });
    }, 100);
  };

  useEffect(() => {
    setFadeAnimation(DATA.map((item, ind) => true));
  }, [ active ]);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      console.log(window.scrollY)
      setScroll(window.scrollY > 450);
    });
  }, []);

  return (
    <section id="services-panel" className={classes("position-relative d-flex flex-column", styles.container)}>
      <div className={classes(styles.serviceBg)}>
        <div className={classes(styles.wrap)}>
          <div className={classes(styles.image, styles.bg)}>
            <img src={bg} />
          </div>
          <div className={classes(!scroll ? styles.resetTop: '',styles.image, styles.city)}>
            <img src={city} />
          </div>
          <div className={classes(!scroll ? styles.resetTop: '',styles.image, styles.train)}>
            <img src={train} />
          </div>
          <div className={classes(!scroll ? styles.resetTop: '',styles.image, styles.building)}>
            <img src={building} />
          </div>
        </div>
        <div className={classes(styles.noise)}></div>
        <div className={classes(styles.shine)}></div>
        <div className={classes(styles.dark)}></div>
      </div>
      {/*{ParallaxAnimation}*/}
      <Container className={classes(styles.top)} fluid>
        <Row>
          <Col xs={12} lg={5} className="p-0">
            <h3 className={classes("text-title ms-4 mb-0", styles.title)}>
              /Services
            </h3>
          </Col>
          <Col xs={12} lg={7} className="p-0">
            <div className={classes("mt-2 mt-lg-0 ms-4 ms-lg-0", styles.text)}>
              <span className="d-inline-flex flex-column ff-poppins text-white fw-normal fs-10 ls-1 px-3 px-lg-0">
                <span>We can experiment with our small size</span>
                <span>and create really cool systems.</span>
              </span>
            </div>
          </Col>
        </Row>
        <span className="invisible"></span>
      </Container>
      <Container className={styles.table} fluid>
        {DATA.map((item, index) => (
          <Row
            key={index}
            className={classes(styles.item, active === index && styles.selected)}
            role="button"
            id={`services_accordion_item_${index}`}
            onClick={() => handleClick(index)}
          >
            <Col
              xs={4}
              md={3}
              lg={2}
              xxl={1}
              xl={2}
              className="d-flex justify-content-center align-items-start p-0 border"
            >
              <div className="d-flex justify-content-center align-items-center">
                <img className={classes("me-3", styles.arrow)} src={arrow} alt="arrow" />
                <span
                  className={classes(
                    "ff-triakis fw-normal",
                    styles.number,
                    active === index ? "text-warning" : "text-white",
                  )}
                >{index + 1}</span>
              </div>
            </Col>
            <Col
              xs={8}
              md={9}
              lg={10}
              xl={10}
              xxl={11}
              className="position-relative p-0 border-top border-end border-bottom border-start-0"
            >
              <div
                className={classes(
                  "position-absolute w-100 h-100 d-flex d-lg-flex",
                  active === index ? "d-none" : "d-flex",
                  styles.inner,
                )}
              />
              <div
                className={classes(
                  "d-flex justify-content-start justify-content-lg-between align-items-start",
                  styles.content,
                )}
              >
                <div className={styles.rowAnimation}>
                  <Lottie
                    lottieRef={item.lottie.ref}
                    animationData={item.lottie.animation}
                    autoPlay={false}
                    loop={false}
                    muted
                  />
                </div>
                <span className={classes("text-white ff-triakis fw-normal fs-32", styles.rowPath)}>{item.path}</span>
                <Fade in={fadeAnimation[index]} timeout={500}>
                  <div
                    className={classes(
                      "d-none d-lg-flex flex-column justify-content-start align-items-start",
                      styles.rowDescription,
                    )}
                  >
                    {active === index ? <RowDescription data={item.description} /> : <img src={line1} alt="i" />}
                  </div>
                </Fade>
                <Fade in={fadeAnimation[index]} timeout={500}>
                  <div
                    className={classes(
                      "d-none d-xxl-flex flex-column justify-content-start align-items-start",
                      styles.rowList,
                    )}
                  >
                    {active === index ? <RowList data={item.list} /> : <img src={line2} alt="i" />}
                  </div>
                </Fade>
              </div>

            </Col>
            {active === index && (
              <Col xs={12} className="position-relative d-flex d-lg-none h-100 border-bottom">
                <div className={classes("position-absolute w-100 h-100", styles.mobileInner)} />
                <div className={classes("d-flex flex-column", styles.mobileContent)}>
                  <RowDescription data={item.description} />
                  <div className="d-flex justify-content-between mt-5">
                    <div className="d-flex flex-column">
                      <RowList className="fs-12 mt-2" data={item.list.slice(0, Math.ceil(item.list.length / 2))} />
                    </div>
                    <div className="d-flex flex-column">
                      <RowList className="fs-12 mt-2" data={item.list.slice(Math.ceil(item.list.length / 2))} />
                    </div>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        ))}
      </Container>
      <div className={classes("position-absolute w-100", styles.gradient)} />
    </section>
  );
};
